<template>
  <div id="app">
    <div style="position: relative;display:flex;justify-content: center;align-items: center;">
      <div class="item">
        <el-input v-model="deviceId" placeholder="请输入设备编号" clearable></el-input>
      </div>
      <div class="item">
        <el-button type="primary" @click="searchDevice">查询</el-button>
      </div>
      <div class="item">
            <el-button type="success" @click="batchDownload('add')" icon="el-icon-download">批量下载</el-button>
      </div>
      <div class="item" style="margin-left:auto">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </div>
    </div>
        

    <div class="table">
      <el-table :data="tableList" style="width: 100%" border :max-height="700">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column v-if="tableTitle.includes('编号')" prop="id" label="编号" align="center">
        </el-table-column>
        <el-table-column v-if="tableTitle.includes('设备编号')" prop="deviceId" label="设备编号" align="center">
        </el-table-column>
        <el-table-column v-if="tableTitle.includes('上传时间')" prop="createTime" label="上传时间" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="250">
          <template slot-scope="scope">
            <el-button @click="downloadErCode(scope.row)" type="success" size="small">查看二维码</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    

    <el-pagination :current-page.sync="current" :page-size="limit" background :total="total" layout="total, prev, pager, next, jumper" style="padding-top: 10px;  text-align: center" @current-change="currentPage"></el-pagination>

  </div>
</template>

<script>
import QrCodeDeviceInfo from '@/api/qrCode/QrCodeDeviceInfo'

// npm install jszip --save
// npm install file-saver --save
import JSZip from 'jszip'
import FileSaver from "file-saver"
import BaseData from '@/assets/config/BaseData'
export default {
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '编号',
      }, {
        id: 1,
        title: '设备编号',
      }, {
        id: 2,
        title: '上传时间',
      }],
      tableTitle: ['编号', '设备编号', '上传时间'],

      tableList: [],

      deviceId: '',
      showEdit: false,

      total: 0,
      limit: 10,
      current: 1,

      // erCodeUrl: '',  // 二维码地址
      erCodeUrlList: [],
      erCodeUrlNameList: [],

    };
  },

  created() {
    this.getAllErCodeInfo()
    this.getAll()

  },

  methods: {
    // 选择table类型
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('编号')
      } else {
        this.tableTitle = value
      }
    },

    // 查询所有信息  分页查
    async getAllErCodeInfo() {
      const { data: res } = await QrCodeDeviceInfo.pageQrCodeInfo(this.current, this.limit, this.deviceId)
      this.tableList = res.data.list
      console.log(res);
      this.total = res.data.total
    },

    // 查询所有信息  全部查
    async getAll() {
      this.erCodeUrlNameList = []
      this.erCodeUrlList = []
      const { data: res } = await QrCodeDeviceInfo.pageQrCodeInfo(1, 999, this.deviceId)
      console.log(res);

      for (let i = 0; i < res.data.list.length; i++) {
        res.data.list[i].erCodeUrl = BaseData.baseUrl + res.data.list[i].qrCodeUrl
        this.erCodeUrlList.push(BaseData.baseUrl + res.data.list[i].qrCodeUrl)
        this.erCodeUrlNameList.push(res.data.list[i].deviceId)
      }
      console.log(this.erCodeUrlNameList);
      console.log(this.erCodeUrlList);
    },

    // 查询设备
    searchDevice() {
      if (this.deviceId == '') {
        this.deviceId = null
      }
      this.getAllErCodeInfo()
    },

    // 下载二维码
    downloadErCode(param) {

      this.erCodeUrl = BaseData.baseUrl + param.qrCodeUrl
      let a = document.createElement('a');
      // let event = new MouseEvent("click"); // 创建一个单击事件
      let filename = param.deviceId;      // 下载后显示的文件名称

      // console.log(BaseData.baseUrl);
      // console.log(param.qrCodeUrl);
      console.log(this.erCodeUrl);
      a.href = this.erCodeUrl;
      a.download = filename;
      a.target = "_blank";
      // a.dispatchEvent(event); // 触发a的单击事件
      a.click();
      // console.log(a);
    },

    // 批量下载
    async batchDownload() {


      let blogTitle = '打包图片'
      let zip = new JSZip
      let imgs = zip.folder(blogTitle)
      let baseList = []

      // var arr = ['./img1.png', './img2.png', './img3.png'];
      console.log(this.erCodeUrlList);
      console.log(this.erCodeUrlNameList);
      var arr = this.erCodeUrlList
      var imgNameList = this.erCodeUrlNameList

      //就可以从后端返回的数据里面取到图片名字,push到数组内!
      for (var i = 0; i < arr.length; i++) {
        let image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous');

        image.onload = function () {
          let canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;

          let context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, image.width, image.height);

          let url = canvas.toDataURL(); // 得到图片的base64编码数据
          console.log(url);
          canvas.toDataURL('image/png');

          baseList.push(url.substring(22));    // 去掉base64编码前的 data:image/png;base64,
          console.log(baseList);

          if (baseList.length === arr.length && baseList.length > 0) {
            for (let k = 0; k < baseList.length; k++) {
              imgs.file(imgNameList[k] + '.png', baseList[k], { base64: true })
            }
            zip.generateAsync({ type: 'blob' }).then(function (content) {
              // see FileSaver.js
              FileSaver.saveAs(content, blogTitle + '.zip');
            });
          }
        };
        image.src = arr[i];
      }
    },

    // 分页
    currentPage(value) {
      this.getAllErCodeInfo()
    },

  },
};
</script>

<style scoped lang="scss">
.rows {
  margin: 10px 10px 10px 0;
}
.item {
  margin: 20px 5px;
}
</style>