import request from '@/../utils/request'
const api_name = '/iotechserver/QrCodeDeviceInfoController'

export default {

  // 存二维码的接口
  saveQrCodeImg(deviceId) {
    return request({
      url: `${api_name}/saveQrCodeImg/${deviceId}`,
      method: 'get',
    })
  },


  // 查全部
  pageQrCodeInfo(current, limit, deviceId) {
    return request({
      url: `${api_name}/pageQrCodeInfo/${current}/${limit}`,
      method: 'get',
      params: {
        deviceId: deviceId
      }
    })
  },

  // 删二维码的接口
  deleteQrCodeImg(deviceId) {
    return request({
      url: `${api_name}/deleteQrCodeImg/${deviceId}`,
      method: 'get',
    })
  },
}